import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { all, delay, fork, select } from '@redux-saga/core/effects';
import { v4 as uuidv4, v4 as uuid } from 'uuid';
import { ContentState, convertToRaw, EditorState, RichUtils } from 'draft-js';
import {
  requestCreateLinkComponent,
  requestCreateLinkPage,
  requestDeleteLinkPage,
  requestGetSomePlaylistContent,
  requestSummarizeMultiple,
  requestParser, requestSharedMakeAllPagesIncomplete,
  requestUpdateLinkPage,
  requestUpdatePlaylist, requestUpdatePlaylistManager,
  requestUpdateUserInterface,
  requestAITask,
  requestCollapseLinkPage,
  requestAISearch,
  requestPrepareToFind,
  requestCollapseAllLinkPages,
  requestAISend,
  requestAIUnvectorizeLinkPage,
  requestUpdateUserSettingsPreview, requestUpdatePlaylistText,
  requestStopAIProcessing, requestCheckAISend,
} from '../utils/request';
import {
  EmptyRTBState,
  emptyRTBState,
  generateSimpleRTBState,
  getItemName,
  getLinkPagePosition,
  innerHtmlWebTextTemplate,
  removeStringifiedSymbols, replaceDoubleQuotesFix,
  sanitizeToLoad,
  sanitizeToSave,
} from '../utils/helpers';
import {
  currentPage, getContentData,
  getCurrentPage,
  getCurrentPageLinkPages,
  getLibraryCollections,
  getLibraryComponents,
  getMiniPages,
  getPages, getSelectedPage,
  getUploads,
  getUser,
  sendEventUpdate,
  showErrorMessage,
} from './sagasUtils';
import {
  aiProcessingStatusEnum,
  colorsApprovedButton,
  DEFAULT_DURATION,
  DEFAULT_POSITION_STEP,
  empty, hasAiProcessingStatus, MessageType,
  openModalType,
  PLAYLIST_ELEMENTS,
} from '../utils/constants';
import {
  actionChangeLinkPageTypeR,
  actionCreateLibLinkPagePlaylist,
  actionCreateLinkPageR,
  actionManyUpdatePositionLinkPagesR,
  actionReplaceLinkPagePlaylistPageR,
  actionSaveSummarize,
  actionAIProcessingToggleLoading,
  actionSwitchPage,
  actionUpdateLinkPage,
  actionUpdateLinkPageR,
  actionUpdatePlaylistLastModified,
  actionAIToggleVectorizeLinkPageRS,
} from '../redux/currentPage/action';
import { actionCreator } from '../shared/redux/actionHelper';
import { sendMessage } from './SocketCluster/action';
import { CurrentPage } from '../redux/currentPage/types';
import { calculateTitleForDuplicateLinkPage } from '../utils/helperText';
import {
  actionCancelUpload,
  actionUpdateUpload,
} from '../redux/filesUpload/action';
import { actionCleanBlinkId, actionSetBlinkId, actionShowMessage } from '../redux/support/action';
import { FileUpload } from '../redux/filesUpload/types';
import { actionAddLibraryComponentReduxOnly } from '../redux/library/actions';
import { maxFileSize } from '../GLOBAL_CONST';
import { createUpload } from './uploadSagas';
import { actionChangeTextElementBlockReduxMaker } from '../redux/playlists/action';
import EditPlaylist from '../redux/playlists/types';
import { placementEnum } from '../utils/query/queryEnum';
import {
  createBodyDuplicateLinkPageLexicalText,
  createBodyDuplicateLinkPageText,
  parseFieldsToObj,
} from '../utils/query/helper';
import { notUndoActionUpdate } from './undoSagas';
import { SelectedPage } from '../redux/selectedPage/types';
import { smartFileItemTypeCheck } from '../shared/smartFile/constant';
import i18n from '../i18n';
import { actionOpenAIModal } from '../redux/user/action';

function* editTitleLinkPage(action) {
  try {
    const {
      payload: { isSocket, ...payload },
    } = action;
    const { linkPageId, newTitle } = payload;
    const { id: playlistId } = yield select(getCurrentPage);

    if (isSocket) return;
    let sanitizedValue = newTitle;
    if (typeof newTitle === 'string') sanitizedValue = `"${sanitizeToSave(newTitle)}"`;
    const field = {};
    field.title = sanitizedValue;
    yield requestUpdateLinkPage('name')({
      id: playlistId,
      updateNested: {
        linkPages: [{ id: linkPageId, field }],
      },
    });
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(JSON.stringify(payload)),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* updateDurationLinkPageState(action) {
  try {
    const {
      payload: { isSocket, ...payload },
    } = action;
    const { linkPageId, value } = payload;

    if (isSocket) return;
    const { id: playlistId } = yield select(getCurrentPage);
    let sanitizedValue = value;
    if (typeof value === 'string') sanitizedValue = `"${sanitizeToSave(value)}"`;
    const field = {};
    field.duration = sanitizedValue;
    yield requestUpdatePlaylist()({
      id: playlistId,
      updateNested: {
        linkPages: [{ id: linkPageId, field }],
      },
    });
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(JSON.stringify(payload)),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ChangeTextElementBlockRedux(action) {
  try {
    const {
      payload: { isSocket, ...payload },
    } = action;
    if (isSocket) return;
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({
              ...payload,
              newState: payload.newState.getCurrentContent ? convertToRaw(payload.newState.getCurrentContent()) : payload.newState,
            }),
          ),
          playlistId: payload.playlistId,
        },
        pushToChannel: payload.playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* duplicateLinkPage(action) {
  try {
    const { item, newPosition, playListId, newId, isSocket } = action.payload;
    const { id: userId } = yield select(getUser);
    const linkPages = yield select(getCurrentPageLinkPages);
    const exTitle = calculateTitleForDuplicateLinkPage(
      linkPages,
      getItemName(item),
    );
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({ item, newPosition, playListId, newId }),
            ),
            playlistId: playListId,
          },
          pushToChannel: playListId,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
    const linkPage = {
      id: newId,
      position: newPosition,
      type: item.type,
      owner: item.owner,
      title: sanitizeToLoad(exTitle),
      createDate: Math.round(Date.now() / 1000),
    };
    if (item.type === 'elementText') {
      const newItemId = uuid();
      const newItem = {
        textComponent: { ...item.textComponent, id: newItemId },
      };
      if (!isSocket) {
        yield call(
          requestCreateLinkPage(placementEnum.queryDuplicateManyLinkPageUniversal),
          createBodyDuplicateLinkPageText(
            newItem,
            linkPage,
            playListId,
            userId,
          ),
        );
      }
      yield put(actionCreateLinkPageR(playListId, { ...linkPage, ...newItem }));
      yield put(actionSwitchPage({ newBlockId: newItemId }));
    } else if (smartFileItemTypeCheck.isLexicalText[item.type]) {
      const newItemId = uuid();
      const newItem = {
        textComponent: { ...item.textComponent, id: newItemId },
      };
      if (!isSocket) {
        yield call(
          requestCreateLinkPage(placementEnum.queryDuplicateManyLinkPageUniversal),
          createBodyDuplicateLinkPageLexicalText(
            newItem,
            linkPage,
            playListId,
            userId,
          ),
        );
      }
      yield put(actionCreateLinkPageR(playListId, { ...linkPage, ...newItem }));
      yield put(actionSwitchPage({ newBlockId: newItemId }));
    } else if (item.type === 'pages') {
      if (!isSocket) {
        yield call(
          requestCreateLinkPage(placementEnum.queryDuplicateLinkPageWithPage),
          {
            page: item.page,
            linkPage: { ...linkPage, title: sanitizeToSave(linkPage.title) },
            playlistId: playListId,
            userId,
          },
        );
      }
      yield put(actionCreateLinkPageR(playListId, { ...item, ...linkPage }));
    } else if (item.type === PLAYLIST_ELEMENTS.ElementComponent) {
      if (!isSocket) {
        yield call(
          requestCreateLinkPage(
            placementEnum.queryDuplicateManyLinkPageUniversal,
          ),
          {
            playlistId: playListId,
            userId,
            linkPages: [{
              linkPage: { ...linkPage, title: sanitizeToSave(linkPage.title) },
              libraryComponent: item.libraryComponent,
            }],
          },
        );
      }
      yield put(actionCreateLinkPageR(playListId, { ...item, ...linkPage }));
    } else {
      if (!isSocket) {
        yield call(requestUpdatePlaylist(), {
          id: playListId,
          create: {
            linkPages: [
              {
                id: linkPage.id,
                position: linkPage.position,
                createDate: linkPage.createDate,
                duration: linkPage.duration,
                addOwner: userId,
              },
            ],
          },
        });
      }
      yield put(actionCreateLinkPageR(playListId, { ...linkPage }));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UpdateCollapseLinkPageRS(action) {
  try {
    const { linkPageId, isCollapsed, isCollapsedAll } = action.payload;
    const user = yield select(getUser);
    const currentPage = yield select(getCurrentPage);

    if (!user.id) return;
    if (linkPageId) yield call(requestCollapseLinkPage(linkPageId), { isCollapsed });
    if (isCollapsedAll) yield call(requestCollapseAllLinkPages(currentPage.id), { isCollapsed });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* CreateApproveButton(action) {
  try {
    const { linkPageId, interactiveItemData } = action.payload;
    const currentPage = yield select(getCurrentPage);
    const defaultInteractiveItemData = {
      type: 'approveButton',
      color: colorsApprovedButton[0],
      clickedName: 'Disapprove',
      defaultName: 'Approve',
      buttonId: 'Approve Button',
    };

    const newInteractiveItemData = {
      ...defaultInteractiveItemData,
      ...interactiveItemData,
      isNew: false,
    };

    const page = sanitizeToSave(JSON.stringify(newInteractiveItemData));
    yield call(requestUpdateLinkPage(), {
      id: linkPageId,
      fieldsUpdateObg: { interactiveItemData: `"${page}"` },
      playlistId: currentPage.id,
    });

    yield put(
      actionCreator(
        CurrentPage.UpdateApproveButtonRedux,
        {
          linkPageId,
          interactiveItemData: {
            ...newInteractiveItemData,
            isNew: !interactiveItemData,
          },
          playlistId: currentPage.id,
        },
        'CreateApproveButton',
      ),
    );

    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: CurrentPage.UpdateApproveButtonRedux,
          payload: sanitizeToSave(
            JSON.stringify({
              linkPageId,
              interactiveItemData: { ...newInteractiveItemData, isNew: false },
              playlistId: currentPage.id,
            }),
          ),
          playlistId: currentPage.id,
        },
        pushToChannel: currentPage.id,
      }),
    );
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPage.id,
        lastModifiedDate,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* UpdateApproveButton(action) {
  try {
    const { linkPageId, interactiveItemData } = action.payload;
    const currentPage = yield select(getCurrentPage);
    yield put(
      actionCreator(CurrentPage.UpdateApproveButtonRedux, {
        linkPageId,
        interactiveItemData,
        playlistId: currentPage.id,
      }),
    );
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: CurrentPage.UpdateApproveButtonRedux,
          payload: sanitizeToSave(
            JSON.stringify({
              linkPageId,
              interactiveItemData: { ...interactiveItemData, isNew: false },
              playlistId: currentPage.id,
            }),
          ),
          playlistId: currentPage.id,
        },
        pushToChannel: currentPage.id,
      }),
    );
    yield call(requestUpdateLinkPage(), {
      id: linkPageId,
      playlistId: currentPage.id,
      fieldsUpdateObg: {
        interactiveItemData: `"${sanitizeToSave(
          JSON.stringify(interactiveItemData),
        )}"`,
      },
    });
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPage.id,
        lastModifiedDate,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateLinkPageCaptionShowState(action) {
  try {
    const {
      payload: { linkPageId, value },
    } = action;
    const { id: playlistId } = yield select(getCurrentPage);
    let sanitizedValue = value;
    if (typeof value === 'string') sanitizedValue = `"${sanitizeToSave(value)}"`;
    const field = {};
    field.isShowCaption = sanitizedValue;
    yield call(requestUpdatePlaylist('caption'), {
      id: playlistId,
      updateNested: {
        linkPages: [{ id: linkPageId, field }],
      },
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateLinkPageCaption(action) {
  try {
    const {
      payload: { linkPageId, newCaption, isSocket },
    } = action;
    const { id: playlistId } = yield select(getCurrentPage);
    if (isSocket) return;
    let sanitizedValue = newCaption;
    if (typeof newCaption === 'string') sanitizedValue = `"${sanitizeToSave(newCaption)}"`;
    const field = {};
    field.caption = sanitizedValue;
    yield call(requestUpdatePlaylist('caption'), {
      id: playlistId,
      updateNested: {
        linkPages: [{ id: linkPageId, field }],
      },
    });
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(JSON.stringify(action.payload)),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* DeleteApproveButton(action) {
  try {
    const { linkPageId } = action.payload;
    const currentPage = yield select(getCurrentPage);
    yield call(requestUpdateLinkPage(), {
      id: linkPageId,
      playlistId: currentPage.id,
      fieldsUpdateObg: { interactiveItemData: '""' },
    });

    yield put(
      actionCreator(
        CurrentPage.UpdateApproveButtonRedux,
        { linkPageId, interactiveItemData: null, playlistId: currentPage.id },
        'DeleteApproveButton',
      ),
    );
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: CurrentPage.UpdateApproveButtonRedux,
          payload: sanitizeToSave(
            JSON.stringify({
              linkPageId,
              interactiveItemData: null,
              playlistId: currentPage.id,
            }),
          ),
          playlistId: currentPage.id,
        },
        pushToChannel: currentPage.id,
      }),
    );
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    // yield put(actionUpdatePlaylistLastModified({ id: currentPage.id, lastModifiedDate }));
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPage.id,
        lastModifiedDate,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateIsRemixLockedLinkPageState(action) {
  try {
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    const {
      payload: { linkPageId, value, playlistId, linkPagesIdsMap },
    } = action;
    const { hasBlockedLinkPages: localValue } = yield select(getCurrentPage);
    let sanitizedValue = value;
    if (typeof value === 'string') sanitizedValue = `"${sanitizeToSave(value)}"`;
    const field = {};
    field.isRemixLocked = sanitizedValue;
    let reqArray = [{ id: linkPageId, field }];
    if (linkPagesIdsMap) {
      reqArray = Object.keys(linkPagesIdsMap).map(elem => ({ id: elem, field }));
    }
    yield call(requestUpdatePlaylist('locked'), {
      id: playlistId,
      updateNested: {
        linkPages: reqArray,
      },
    });
    const { data } = yield requestGetSomePlaylistContent([
      'blocked',
      playlistId,
    ]);

    if (data?.Playlist[0]?.hasBlockedLinkPages !== localValue) {
      yield put(
        actionSwitchPage({
          hasBlockedLinkPages: data?.Playlist[0]?.hasBlockedLinkPages,
          lastModifiedDate,
        }),
      );
    } else yield put(actionSwitchPage({ lastModifiedDate }));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* clickToApproveButton(action) {
  try {
    const { linkPageId, isApprove, isMainInterface } = action.payload;
    const currentPage = yield select(getCurrentPage);
    const { id, linkPages } = currentPage;
    const { id: userId } = yield select(getUser);
    if (!userId) return;

    const currentLink = linkPages.find((it) => it.id === linkPageId);
    const linkPageInterfaceId = isMainInterface
      ? currentLink?.mainUserInterface?.id
      : currentLink?.mainUserInterface?.id;

    yield put(
      actionCreator(CurrentPage.ClickToApproveButtonR, {
        linkPageId,
        isApprove,
        playlistId: id,
      }),
    );
    yield call(requestUpdateUserInterface(placementEnum.queryAddUserAnswer), {
      linkPageInterfaceId,
      userId,
      answer: isApprove,
    });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* addEmptyLinkPageToPlaylist(action) {
  try {
    const { playlistId, pageData, isSocket } = action.payload;
    if (isSocket) return;
    const { id: userId } = yield select(getUser);
    const lastModifiedDate = Math.floor(Date.now() / 1000);

    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(JSON.stringify({ playlistId, pageData })),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );

    const createDate = Math.round(new Date().getTime() / 1000);

    yield call(requestUpdatePlaylist(), {
      id: playlistId,
      create: {
        linkPages: [
          {
            id: pageData.id,
            position: pageData.position,
            createDate,
            addOwner: userId,
            mainUserInterface: pageData.mainUserInterface,
          },
        ],
      },
    });
    yield put(
      actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* removeLinkPageFromPlaylist(action) {
  try {
    const { linkPageIds, textComponentIds, playlistId, isSocket } = action.payload;
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({
                linkPageIds,
                textComponentIds,
                playlistId,
              }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );

      yield call(requestDeleteLinkPage(), {
        ids: Array.isArray(linkPageIds) ? linkPageIds : [linkPageIds],
        playlistId,
        textComponentIds: Array.isArray(textComponentIds) ? textComponentIds : [textComponentIds],
      });
    }
    const currentPage = yield select(getCurrentPage);
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPage.id,
        lastModifiedDate,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* changeLinkPageType(action) {
  try {
    const { linkPageId, playlistId, componentId, newType, isSocket, text, updateCBaction } = action.payload;
    const { id: userId } = yield select(getUser);
    const pageData = {};
    if (newType && newType !== 'elementText' && !smartFileItemTypeCheck.isLexicalText[newType]) {
      pageData.id = linkPageId;
      pageData.type = newType;
      if (!isSocket) {
        yield call(requestUpdatePlaylist(), {
          id: playlistId,
          updateNested: {
            linkPages: [{ id: pageData.id, field: parseFieldsToObj(pageData) }],
          },
        });
      }
    } else if (newType === 'placeholder') {
      pageData.id = linkPageId;
      pageData.type = 'placeholder';
      if (!isSocket) {
        yield call(requestUpdatePlaylist(), {
          id: playlistId,
          updateNested: {
            linkPages: [{ id: pageData.id, field: parseFieldsToObj(pageData) }],
          },
        });
      }
    } else {
      pageData.id = linkPageId;
      pageData.type = newType;
      pageData.isEdit = true;
      pageData.duration = DEFAULT_DURATION;
      pageData.createDate = Math.floor(Date.now() / 1000);
      console.log(text);
      const newState = text ? generateSimpleRTBState(text) : EmptyRTBState;
      pageData.textComponent = {
        type: 'text',
        id: componentId || uuid(),
        state: newState,
        innerHtml: innerHtmlWebTextTemplate(''),
      };
      if (!isSocket) {
        yield put(actionChangeLinkPageTypeR(playlistId, pageData));
        yield put(actionSwitchPage({ newBlockId: pageData.textComponent.id }));
        let newState = JSON.stringify({});

        // TODO rework it after the text is replaced with lexical
        if (newType === 'elementText') {
          const objState = convertToRaw(
            pageData.textComponent.state.getCurrentContent(),
          );

          objState.blocks.forEach((it) => {
            it.text = sanitizeToSave(it.text);
          });
          newState = replaceDoubleQuotesFix(JSON.stringify(objState));
        }
        yield call(requestUpdateLinkPage(), {
          id: pageData.id,
          playlistId,
          fieldsUpdateObg: { type: `"${newType}"` },
          create: {
            textComponent: [
              {
                id: pageData.textComponent.id,
                content: newState,
                type: 'text',
                position: 100000,
                addOwner: userId,
              },
            ],
          },
        });
      } else {
        yield put(actionSwitchPage({ newBlockId: pageData.textComponent.id }));
      }
    }
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: CurrentPage.ChangeLinkPageTypeR,
            payload: sanitizeToSave(
              JSON.stringify({
                playlistId,
                pageData,
              }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }

    if (pageData.type !== 'elementText') {
      yield put(actionChangeLinkPageTypeR(playlistId, pageData));
    }

    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
    );
    if (updateCBaction) {
      yield put(updateCBaction(pageData.textComponent.id, pageData.textComponent.innerHtml));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* cancelUploadAndChangeLinkPageTypeS(action) {
  try {
    const { linkPageId, playlistId, componentId, isSocket } = action.payload;
    const pageData = {};
    yield put(actionCancelUpload(componentId));

    pageData.id = linkPageId;
    pageData.type = 'upload';
    if (!isSocket) {
      yield call(requestUpdatePlaylist(), {
        id: playlistId,
        updateNested: {
          linkPages: [{ id: linkPageId, field: { type: '"upload"' } }],
        },
      });
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({ linkPageId, playlistId, componentId }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
    yield put(actionChangeLinkPageTypeR(playlistId, pageData));

    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* updateLinkPage(action) {
  try {
    const { playlistId, pageData, isNew } = action.payload;
    const { id: userId } = yield select(getUser);
    if (isNew) {
      yield call(requestUpdatePlaylist(), {
        id: playlistId,
        create: {
          linkPages: [
            {
              id: pageData.id,
              type: pageData.type,
              position: pageData.position,
              createDate: Math.floor(Date.now() / 1000),
              addOwner: userId,
              libraryComponentId: pageData.libraryComponent?.id,
              textComponent: pageData.textComponent,
            },
          ],
        },
      });
    } else {
      yield call(requestUpdatePlaylist(), {
        id: playlistId,
        updateNested: {
          linkPages: [{ id: pageData.id, field: parseFieldsToObj(pageData) }],
        },
      });
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* replaceLinkPagePlaylistPage(action) {
  try {
    const {
      linkPageId,
      playlistId,
      previousFolderIdDrag,
      idDragPage,
      isSocket,
    } = action.payload;
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({
                linkPageId,
                playlistId,
                previousFolderIdDrag,
                idDragPage,
              }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
    const pages = yield select(getPages);
    const miniPages = yield select(getMiniPages);
    const components = yield select(getLibraryComponents);
    const collections = yield select(getLibraryCollections);
    const linkPages = yield select(getCurrentPageLinkPages);
    const oldLinkData = linkPages.find((lp) => lp.id === linkPageId) || empty;
    let itemData;
    if (previousFolderIdDrag && pages[previousFolderIdDrag]) {
      itemData = pages[previousFolderIdDrag].nestedPage[idDragPage];
    } else if (previousFolderIdDrag && collections[previousFolderIdDrag]) {
      itemData = collections[previousFolderIdDrag].nestedPage[idDragPage];
    } else if (pages[idDragPage]) {
      itemData = pages[idDragPage];
    } else if (components[idDragPage]) {
      itemData = components[idDragPage];
    }

    const linkPageData = {
      ...oldLinkData,
      id: linkPageId,
    };

    if (itemData.itemType === 'page') {
      linkPageData.page = { ...itemData, ...miniPages[itemData.id] };
      linkPageData.type = 'pages';
      if (!isSocket) {
        yield call(
          requestUpdateLinkPage(placementEnum.queryReplaceLinkPageToPage),
          { pageData: linkPageData },
        );
      }
    } else if (itemData.itemType === 'component') {
      linkPageData.libraryComponent = { ...itemData };
      linkPageData.type = PLAYLIST_ELEMENTS.ElementComponent;
      linkPageData.contentType = itemData.type;
      if (!isSocket) {
        const field = parseFieldsToObj(linkPageData);
        field.libraryComponent = `{connect:{where:{node:{id:"${linkPageData.libraryComponent.id}"}}}}`;
        yield call(requestUpdatePlaylist(), {
          id: playlistId,
          updateNested: {
            linkPages: [
              {
                id: linkPageData.id,
                field,
              },
            ],
          },
        });
      }
    }
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    if (!isSocket) {
      yield put(
        actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
      );
    }

    yield put(
      actionReplaceLinkPagePlaylistPageR(linkPageId, playlistId, linkPageData),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* movePageWithinPlaylist(action) {
  try {
    const { updatedLinkPages, playlistId, isSocket } = action.payload;
    yield put(
      actionManyUpdatePositionLinkPagesR(
        playlistId,
        updatedLinkPages,
        isSocket,
      ),
    );
    if (!isSocket) {
      yield call(requestUpdatePlaylist(), {
        id: playlistId,
        updateNested: {
          linkPages: updatedLinkPages.map(({ id, position }) => ({
            id,
            field: { position },
          })),
        },
      });
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({
                updatedLinkPages: updatedLinkPages.map((lp) => {
                  if (lp.type !== 'elementText') return lp;
                  return {
                    ...lp,
                    textComponent: {
                      ...lp.textComponent,
                      state: convertToRaw(
                        lp.textComponent.state.getCurrentContent(),
                      ),
                    },
                  };
                }),
                playlistId,
              }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* movePageWithinPlaylistMaker(action) {
  try {
    const { id, position, isSocket } = action.payload;
    yield put(
      actionCreator(
        CurrentPage.UpdatePositionLinkPagesMaker,
        { [id]: { position } },
        isSocket,
      ),
    );
    const currentPage = yield select(getCurrentPage);

    if (!isSocket) {
      yield call(requestUpdatePlaylist('position'), {
        id: currentPage.id,
        updateNested: {
          linkPages: [{ id, position }].map(({ id, position }) => ({
            id,
            field: { position },
          })),
        },
      });
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({
                id,
                position,
                playlistId: currentPage.id,
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
      yield call(sendEventUpdate, ({}));
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createLibLinkPagePlaylist(action) {
  try {
    const {
      newLinkPage,
      playlistId,
      previousFolderIdDrag,
      idDragPage,
      isSocket,
    } = action.payload;
    const currentPage = yield select(getCurrentPage);
    const pages = yield select(getPages);
    const components = yield select(getLibraryComponents);
    const collections = yield select(getLibraryCollections);
    const {
      id: userId,
      last_name,
      first_name,
      avatarUrlVerySmall,
    } = yield select(getUser);
    const owner = { id: userId, last_name, first_name, avatarUrlVerySmall };
    if (isSocket) {
      yield put(actionCreateLinkPageR(playlistId, newLinkPage));
      yield put(actionSetBlinkId([newLinkPage.id]));
      return;
    }
    let itemData = {};
    if (newLinkPage?.type === 'elementText' || smartFileItemTypeCheck.isLexicalText[newLinkPage?.type]) {
      itemData = newLinkPage.textComponent;
    } else if (previousFolderIdDrag && pages[previousFolderIdDrag]) {
      itemData = pages[previousFolderIdDrag].nestedPage[idDragPage];
    } else if (previousFolderIdDrag && collections[previousFolderIdDrag]) {
      itemData = collections[previousFolderIdDrag].nestedPage[idDragPage];
    } else if (pages[idDragPage]) {
      itemData = pages[idDragPage];
    } else if (components[idDragPage]) {
      itemData = components[idDragPage];
    } else if (newLinkPage.libraryComponent) {
      itemData = newLinkPage.libraryComponent;
    } else {
      itemData = newLinkPage.textComponent;
    }
    const linkPageData = {
      ...newLinkPage,
      owner,
    };
    if (!isSocket) {
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: CurrentPage.CreateLinkPageR,
            payload: sanitizeToSave(JSON.stringify({ playlistId, pageData: newLinkPage })),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
    }
    if (newLinkPage?.type === 'elementText' && itemData?.state) {
      // in such an implementation it could not work
      if (!isSocket) {
        const newState = JSON.stringify(convertToRaw(itemData.state?.getCurrentContent()))
          .replaceAll('\'', '$@quote').replaceAll('"', '\'')
          .replaceAll('\\\'', '\\"');
        yield call(requestCreateLinkPage(placementEnum.queryCreateTextLinkPage), {
          pageData: linkPageData, playlistId, userId, newState,
        });
      }
      yield put(actionCreateLinkPageR(playlistId, linkPageData));
    } else if (smartFileItemTypeCheck.isLexicalText[newLinkPage?.type]) {
      if (!isSocket) {
        const newState = sanitizeToSave(JSON.stringify(itemData.state));
        yield put(actionCreateLinkPageR(playlistId, linkPageData));

        yield call(requestCreateLinkPage(placementEnum.queryCreateTextLinkPage), {
          pageData: linkPageData, playlistId, userId, newState,
        });
      }
    } else if (!itemData?.type) {
      if (!isSocket) {
        const createDate = Math.round(new Date().getTime() / 1000);
        yield put(actionCreateLinkPageR(playlistId, linkPageData));

        yield call(requestUpdatePlaylist(), {
          id: playlistId,
          create: {
            linkPages: [
              {
                id: newLinkPage.id,
                position: newLinkPage.position,
                createDate,
                addOwner: userId,
              },
            ],
          },
        });
      }
    } else if (itemData?.type !== 'component') {
      linkPageData.libraryComponent = { ...itemData };
      linkPageData.type = PLAYLIST_ELEMENTS.ElementComponent;
      linkPageData.contentType = itemData.type;
      yield put(actionCreateLinkPageR(playlistId, linkPageData));
      const lastModifiedDate = Math.floor(Date.now() / 1000);
      yield put(
        actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
      );
      yield put(
        actionSwitchPage({ idDragPage: null, previousFolderIdDrag: null }),
      );

      if (!isSocket) {
        const linkPages = [
          {
            id: linkPageData.id,
            type: linkPageData.type,
            position: linkPageData.position,
            duration: linkPageData.duration,
            createDate: lastModifiedDate,
            addOwner: userId,
            libraryComponentId: linkPageData.libraryComponent?.id,
            textComponent: linkPageData.textComponent,
          },
        ];
        yield call(requestUpdatePlaylist(), {
          id: playlistId || currentPage.id,
          create: {
            linkPages,
          },
        });
        yield put(actionCreator(CurrentPage.checkAndSendToAI, { linkPages }));

        yield call(sendEventUpdate, ({}));
      }
      yield put(actionSetBlinkId([newLinkPage.id]));
      yield delay(3000);
      yield put(actionCleanBlinkId());
    }
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createMultipleLibLinkPagePlaylist(action) {
  try {
    const { newLinkPages, playlistId } = action.payload;
    yield all(
      newLinkPages.map((newLinkPage) => {
        // dispatch(actionCreateLibLinkPagePlaylist(newLinkPage, playlistId, previousFolderIdDrag, idDragPage));
        return put(
          actionCreateLibLinkPagePlaylist(
            newLinkPage,
            playlistId,
            null,
            newLinkPage.idDragPage,
            action.metod,
          ),
        );
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* createLinkPageWithURL(action) {
  try {
    const { newPosition, urlText, newId } = action.payload;
    const {} = action.payload;
    const newLinkPageId = newId ?? uuidv4();
    const currentPage = yield select(getCurrentPage);
    const user = yield select(getUser);
    const { id: userId } = user;

    const linkPages = yield select(getCurrentPageLinkPages);

    const oldListLinkPages = linkPages.length
      ? linkPages.sort((a, b) => a.position - b.position)
      : [];
    const componentId = uuidv4();
    const newLinkPagePosition = newPosition
      || getLinkPagePosition(oldListLinkPages.length - 1, oldListLinkPages);

    yield call(requestUpdatePlaylist(), {
      id: currentPage.id,
      create: {
        linkPages: [
          {
            id: newLinkPageId,
            type: PLAYLIST_ELEMENTS.ElementComponent,
            position: newLinkPagePosition,
            createDate: null,
            addOwner: userId,
            libraryComponentId: undefined,
            textComponent: undefined,
          },
        ],
      },
    });
    const pageData = {
      id: newLinkPageId,
      type: PLAYLIST_ELEMENTS.ElementComponent,
      position: newLinkPagePosition,
      owner: user,
      libraryComponent: {
        id: componentId,
        type: 'text/html',
        title: 'upload',
        isUpload: true,
      },
    };
    yield put(actionCreateLinkPageR(currentPage.id, pageData));
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: CurrentPage.CreateLinkPageR,
          payload: sanitizeToSave(
            JSON.stringify({
              playlistId: currentPage.id,
              pageData,
            }),
          ),
          playlistId: currentPage.id,
        },
        pushToChannel: currentPage.id,
      }),
    );
    yield put(
      actionCreator(FileUpload.CreateUploadR, {
        newId: componentId,
      }),
    );
    const parsedUrl = yield call(requestParser, { url: urlText });

    const {
      data: { data },
    } = parsedUrl;
    yield call(sendEventUpdate, ({}));
    const response = yield call(requestCreateLinkComponent, {
      urlText,
      componentId,
      data: {
        ...data,
        title: sanitizeToSave(removeStringifiedSymbols(data.title)),
        description: sanitizeToSave(removeStringifiedSymbols(data.description)),
        subtitle: sanitizeToSave(removeStringifiedSymbols(data.subtitle)),
        linkPageId: newLinkPageId,
      },
    });
    if (response.data.error) {
      yield put(
        actionUpdateUpload({
          status: 'ERROR',
          id: newLinkPageId,
          success: true,
          errorMsg: response.data.error,
        }),
      );
    } else {
      const {
        data: { data: componentData },
      } = response;
      const payload = { ...componentData, isUpload: false };

      yield put(actionAddLibraryComponentReduxOnly(payload));
      const newPageData = {
        id: newLinkPageId,
        type: PLAYLIST_ELEMENTS.ElementComponent,
        components: [componentData],
        libraryComponent: { ...componentData, isUpload: false },
      };
      yield put(actionUpdateLinkPage(currentPage.id, newPageData));
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: CurrentPage.UpdateLinkPageR,
            payload: sanitizeToSave(
              JSON.stringify({
                playlistId: currentPage.id,
                pageData: newPageData,
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
      yield put(
        actionUpdateUpload({ status: 'READY', id: componentId, success: true }),
      );
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: FileUpload.UpdateUpload,
            payload: sanitizeToSave(
              JSON.stringify({
                status: 'READY',
                id: componentId,
                success: true,
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
      yield delay(1000);

      yield put(
        actionUpdateUpload({
          status: 'READY',
          id: componentId,
          isUpload: false,
          success: false,
        }),
      );
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: FileUpload.UpdateUpload,
            payload: sanitizeToSave(
              JSON.stringify({
                status: 'READY',
                id: componentId,
                isUpload: false,
                success: false,
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
    }
  } catch (err) {
    yield put(actionShowMessage({
      type: MessageType.ReachedUploadsLimitForPlan,
    }));
  }
}

function* createAndUploadLinkPage(action) {
  try {
    const {
      file,
      index,
      history,
      newPosition,
      currentItemId,
      isAddFavorites,
      tags,
      replace,
      newId,
    } = action.payload;
    const uploads = yield select(getUploads);
    const currentPage = yield select(getCurrentPage);
    const linkPages = yield select(getCurrentPageLinkPages);
    const user = yield select(getUser);

    const oldListLinkPages = linkPages.length
      ? linkPages.sort((a, b) => a.position - b.position)
      : [];

    const sortedUploads = Object.values(uploads || {}).sort((a, b) => {
      if (a.errorMsg) return -1;
      if (b.errorMsg) return 1;
      return b.position - a.position;
    });

    const newLinkPagePosition = newPosition
      || getLinkPagePosition(oldListLinkPages.length - 1, oldListLinkPages);
    const newLibraryComponentId = uuidv4();
    let newLinkPageId;
    if (currentItemId) newLinkPageId = currentItemId;
    else newLinkPageId = newId || uuidv4();

    if (currentItemId) {
      yield put(
        actionUpdateLinkPageR(currentPage.id, {
          id: currentItemId,
          type: PLAYLIST_ELEMENTS.ElementComponent,
          libraryComponent: {
            id: newLibraryComponentId,
            type: file.type,
            title: file.name,
            size: file.size,
            isUpload: true,
          },
        }),
      );
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: CurrentPage.UpdateLinkPageR,
            payload: sanitizeToSave(
              JSON.stringify({
                playlistId: currentPage.id,
                pageData: {
                  id: currentItemId,
                  type: 'upload',
                  libraryComponent: {
                    id: newLibraryComponentId,
                    type: file.type,
                    title: file.name,
                    size: file.size,
                    isUpload: false,
                    isSocket: true,
                  },
                },
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
    } else {
      yield put(
        actionCreateLinkPageR(currentPage.id, {
          id: newLinkPageId,
          type: PLAYLIST_ELEMENTS.ElementComponent,
          position: newLinkPagePosition,
          owner: user,
          libraryComponent: {
            id: newLibraryComponentId,
            type: file.type,
            title: file.name,
            size: file.size,
            isUpload: true,
          },
        }),
      );
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: CurrentPage.UpdateLinkPageR,
            payload: sanitizeToSave(
              JSON.stringify({
                playlistId: currentPage.id,
                pageData: {
                  id: newLinkPageId,
                  type: 'upload',
                  position: newLinkPagePosition,
                  libraryComponent: {
                    id: newLibraryComponentId,
                    type: file.type,
                    title: file.name,
                    size: file.size,
                    isUpload: false,
                    isSocket: true,
                  },
                },
              }),
            ),
            playlistId: currentPage.id,
          },
          pushToChannel: currentPage.id,
        }),
      );
    }
    let uploadObj;
    if (file.size > maxFileSize.bytes) {
      uploadObj = {
        newId: newLibraryComponentId,
        file,
        title: file.name,
        position: sortedUploads.length
          ? sortedUploads[0]?.position + 1
          : index + 1,
        type: file.type || 'unknown_type',
        errorMsg: `${i18n.t('fileSizeMoreThanT')} ${maxFileSize.text}`,
        history,
        tags,
        replace,
      };
    } else {
      uploadObj = {
        newId: newLibraryComponentId,
        file,
        title: file.name,
        position: sortedUploads[0]?.position + 1,
        type: file.type || 'unknown_type',
        history,
        tags,
        replace,
      };

      // const workerTask = yield fork(createUpload,
      // { payload: { ...uploadObj, hasLinkPage: newLinkPageId, playlistId: currentPage.id } });
      // yield put(actionCreateUploadR(uploadObj));
      // yield put(actionUpdateUpload({ id: newLibraryComponentId, workerTask, size: file?.size }));
      // // const components = yield select(getLibraryComponents);
      // // const linkPages = yield select(getLinkPageItem(currentPage.id));
      //
      // // yield put(actionUpdateLinkPage(currentPage.id, {
      // //   id: newLinkPageId,
      // //   type: 'component',
      // //   position: linkPages[newLinkPageId].position,
      // //   libraryComponent: { id: newLibraryComponentId, ...components[newLibraryComponentId] } }, true));
    }
    uploadObj.isFavorite = !!isAddFavorites;
    // yield put(actionCreateUploadR(uploadObj));
    const workerTask = yield fork(createUpload, {
      payload: {
        ...uploadObj,
        hasLinkPage: newLinkPageId,
        playlistId: currentPage.id,
        isNew: !currentItemId,
        replace,
        tags,
      },
    });
    yield put(
      actionUpdateUpload({
        id: newLibraryComponentId,
        workerTask,
        size: file?.size,
      }),
    );
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({
        id: currentPage.id,
        lastModifiedDate,
      }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* ClearLinkPageType(action) {
  try {
    const { linkPageId, oldType, playlistId, addedElementId, isSocket } = action.payload;
    const pageData = {
      id: linkPageId,
      type: '',
    };
    if (!isSocket) {
      yield call(requestUpdateLinkPage(placementEnum.queryClearLinkPage), {
        linkPageId,
        oldType,
        addedElementId,
      });
      yield put(
        sendMessage({
          dataSend: {
            sendChannelPlaylist: 'sendChannel',
            type: action.type,
            payload: sanitizeToSave(
              JSON.stringify({
                linkPageId,
                oldType,
                playlistId,
                addedElementId,
              }),
            ),
            playlistId,
          },
          pushToChannel: playlistId,
        }),
      );
    }
    yield put(actionChangeLinkPageTypeR(playlistId, pageData));
    const lastModifiedDate = Math.floor(Date.now() / 1000);
    yield put(
      actionUpdatePlaylistLastModified({ id: playlistId, lastModifiedDate }),
    );
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* TurnIntoBlockMaker(action) {
  try {
    const {
      payload: { playlistId, type, linkPageId },
    } = action;
    const currentPage = yield select(getCurrentPage);

    const item = currentPage.linkPages.find((it) => it.id === linkPageId);
    const textComponent = item.textComponent;

    const content = {};
    if (textComponent.type === type) return;
    content.state = RichUtils.toggleBlockType(textComponent.state, type);

    const type1 = `type: "${type}"`;
    const calcFiledNested = `
    textComponent:{
      where:{node:{  id: "${textComponent.id}"}}
        update:{ node:{
        ${type1}
        content: ${
  content.state.getCurrentContent
          && `"${JSON.stringify(convertToRaw(content.state.getCurrentContent()))
            .replaceAll("'", '$@quote')
            .replaceAll('"', "'")
            .replaceAll("\\'", '\\"')}`
}"
       }
        }}`;
    yield notUndoActionUpdate(
      actionChangeTextElementBlockReduxMaker(
        textComponent.id,
        content.state,
        null,
        playlistId,
        linkPageId,
        type,
      ),
    );
    yield call(requestUpdatePlaylist(), {
      id: playlistId,
      updateNested: {
        linkPages: [
          {
            id: linkPageId,
            fieldNested: calcFiledNested,
          },
        ],
      },
    });
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}

function* DeleteLinkPage(action) {
  const { playlistId, linkPageId, lastModifiedDate, isSocket } = action.payload;

  yield put(
    actionCreator(CurrentPage.DeleteLinkPageR, {
      playlistId,
      linkPageId,
      lastModifiedDate,
    }),
  );

  if (!isSocket) {
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({ playlistId, linkPageId, lastModifiedDate }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  }
}

function* AddManyLinkPages(action) {
  const { playlistId, blocks, lastModifiedDate, isSocket } = action.payload;

  yield put(
    actionCreator(CurrentPage.AddManyLinkPagesR, {
      playlistId,
      blocks,
      lastModifiedDate,
    }),
  );

  if (!isSocket) {
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({ playlistId, blocks, lastModifiedDate }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  }
}

function* RemoveManyLinkPages(action) {
  const { playlistId, blocks, lastModifiedDate, isSocket } = action.payload;

  if (!isSocket) {
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({ playlistId, blocks, lastModifiedDate }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  }
}

function* insertGoogleUploadBlock(action) {
  const { playlistId, linkPage, lastModifiedDate, isSocket } = action.payload;

  yield put(
    actionCreator(CurrentPage.InsertGoogleUploadBlockR, {
      playlistId,
      linkPage,
      lastModifiedDate,
    }),
  );

  if (!isSocket) {
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({ linkPage, playlistId, lastModifiedDate }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  }
}

function* UpdateGoogleUploadBlock(action) {
  const { playlistId, linkPage, lastModifiedDate, isSocket } = action.payload;

  yield put(
    actionCreator(CurrentPage.UpdateGoogleUploadBlockR, {
      playlistId,
      linkPage,
      lastModifiedDate,
      isSocket,
    }),
  );

  if (!isSocket) {
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({ linkPage, playlistId, lastModifiedDate }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    yield call(sendEventUpdate, ({}));
  }
}

function* ChangeTextElementBlockReduxMakerSocket(action) {
  try {
    const {
      payload: { isSocket, type, blockId, linkPageId },
    } = action;
    const { payload } = action;
    if (isSocket) return;
    const { id: playlistId } = yield select(getCurrentPage);
    yield put(
      sendMessage({
        dataSend: {
          sendChannelPlaylist: 'sendChannel',
          type: action.type,
          payload: sanitizeToSave(
            JSON.stringify({
              ...payload,
              newState: payload.newState.getCurrentContent ? convertToRaw(payload.newState.getCurrentContent()) : payload.newState,
            }),
          ),
          playlistId,
        },
        pushToChannel: playlistId,
      }),
    );
    if (smartFileItemTypeCheck.isLexicalText[type]) {
      requestUpdatePlaylistText()({
        id: playlistId,
        linkPageId,
        blockId,
        content: JSON.stringify(payload.newState),
      });
    }
    yield call(sendEventUpdate, ({}));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* MarkAllLinkPagesAsIncomplete(action) {
  try {
    const {
      payload: { wrapperId },
    } = action;
    const { id: userId } = yield select(getUser);
    yield call(requestSharedMakeAllPagesIncomplete(wrapperId));
    yield call(requestUpdatePlaylistManager, {
      id: wrapperId,
      disconnect: { readByUsers: [userId] },
    });
    yield put(
      actionCreator(EditPlaylist.MarkViewedR, {
        isFinished: true,
      }),
    );
    yield put(
      actionShowMessage({
        type: MessageType.viewersProgressReset,
      }),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* AddMultipleFromSideBar(action) {
  try {
    const pages = yield select(getSelectedPage);
    const contentData = yield select(getContentData);
    const { id } = yield select(currentPage);
    const pageIds = Object.keys(pages).filter(key => !!pages[key]);
    const linkPages = yield select(getCurrentPageLinkPages);
    let step = 1;
    if (linkPages.length) {
      step = parseFloat(linkPages[0].position / (pageIds.length + 1)).toFixed(2);
    }
    const newLinkPages = pageIds.map((id, index) => ({
      id: uuid(),
      type: PLAYLIST_ELEMENTS.ElementComponent,
      position: step * (index + 1),
      idDragPage: id,
      duration: contentData[id].duration || DEFAULT_DURATION,
    }));
    yield put(
      actionCreator(CurrentPage.MultipleCreateLibLinkPagePlaylist, {
        newLinkPages,
        playlistId: id,
      }),
    );
    yield put(actionCreator(SelectedPage.RemoveAllSelected));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* AddMultipleFromSideBarDnD(action) {
  try {
    const { index, selectedPage } = action.payload;
    const { id, linkPages } = yield select(currentPage);
    const contentData = yield select(getContentData);
    let step;
    let previousPosition;
    if (index === 'last') {
      const nextPosition = linkPages[linkPages.length - 1]?.position || DEFAULT_POSITION_STEP;
      previousPosition = nextPosition + DEFAULT_POSITION_STEP;
      step = DEFAULT_POSITION_STEP;
    } else {
      const nextPosition = linkPages[index]?.position || DEFAULT_POSITION_STEP;
      previousPosition = linkPages[index - 1]?.position || 0;
      step = (nextPosition - previousPosition)
        / (Object.values(selectedPage).length + 1) || DEFAULT_POSITION_STEP;
    }
    const newLinkPages = Object.keys(selectedPage).map((id, index) => ({
      id: uuid(),
      type: PLAYLIST_ELEMENTS.ElementComponent,
      position: previousPosition + step * (index + 1),
      idDragPage: id,
      duration: contentData[id].duration || DEFAULT_DURATION,
    }));
    yield put(
      actionCreator(CurrentPage.MultipleCreateLibLinkPagePlaylist, {
        newLinkPages,
        playlistId: id,
      }),
    );
    yield put(actionCreator(SelectedPage.RemoveAllSelected));
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* AddSoloFromSideBarDnD(action) {
  try {
    const { idDragPage, newPosition } = action.payload;
    const user = yield select(getUser);
    const { id } = yield select(currentPage);
    const contentData = yield select(getContentData);
    const newLinkPage = {
      id: uuid(),
      type: PLAYLIST_ELEMENTS.ElementComponent,
      position: newPosition,
      duration: contentData[idDragPage].duration || DEFAULT_DURATION,
      owner: {
        id: user.id,
        last_name: user.last_name,
        avatarUrlVerySmall: user.avatarUrlVerySmall,
        first_name: user.first_name,
      },
    };
    yield put(actionSetBlinkId([newLinkPage.id]));
    yield put(
      actionCreateLibLinkPagePlaylist(
        newLinkPage,
        id,
        null,
        idDragPage,
      ),
    );
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}
function* prepareToFind(action) {
  try {
    const {
      payload: { playlistManagerId },
    } = action;
    yield put(actionAIProcessingToggleLoading(true, 'find'));
    yield call(requestPrepareToFind(playlistManagerId));
    yield put(actionAIProcessingToggleLoading(false, null));
  } catch (e) {
    yield showErrorMessage(e, action);
  }
}
function* summarize(action) {
  try {
    const {
      payload: { linkPages, userTask },
    } = action;
    yield put(actionAIProcessingToggleLoading(true, 'summarize'));
    const response = yield call(requestSummarizeMultiple(linkPages), { linkPages, user_text: userTask });
    if (response.data.taskIds) {
      yield put(
        actionCreator(CurrentPage.SetProcessingItemsInSmartfile,
          { ids: response.data.taskIds }));
    }
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* stopAIProcessing(action) {
  try {
    const {
      payload: { taskIds },
    } = action;
    yield call(requestStopAIProcessing, { taskIds });
    yield put(actionAIProcessingToggleLoading(false, null));
    yield put(
      actionCreator(CurrentPage.SetProcessingItemsInSmartfile,
        { ids: [] }));
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* aiTask(action) {
  try {
    const {
      payload: { linkPages, userTask, playlistId },
    } = action;

    yield put(actionAIProcessingToggleLoading(true, 'ai_task'));
    const response = yield call(requestAITask, { linkPages, user_text: userTask, playlistId });

    if (response.data.taskIds) {
      yield put(
        actionCreator(CurrentPage.SetProcessingItemsInSmartfile,
          { ids: response.data.taskIds },
        ));
    }
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* aiSend(action) {
  try {
    const {
      payload: { linkPages },
    } = action;
    // TODO UPDATE

    yield put(actionAIProcessingToggleLoading(true, 'ai_send'));
    yield put(
      actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
        { linkPageIds: linkPages, status: aiProcessingStatusEnum.ITEM_PENDING_IN_AI }));
    const response = yield call(requestAISend, { linkPages });
    if (response.data.taskIds) {
      yield put(
        actionCreator(CurrentPage.SetProcessingItemsInSmartfile,
          { ids: response.data.taskIds }));
    }
    yield put(actionAIToggleVectorizeLinkPageRS(linkPages, true));
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* checkAndSendToAI(action) {
  try {
    const {
      payload: { linkPages },
    } = action;
    const linkPageIds = linkPages.map(i => i.id);
    yield put(actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
      { linkPageIds, status: aiProcessingStatusEnum.ITEM_PENDING_IN_AI },
    ));

    const { data } = yield call(requestCheckAISend, { linkPages: linkPageIds });
    const item = data.linkPages[0].libraryComponent[0];
    if (hasAiProcessingStatus[item.parsedStatusByAi]) {
      yield put(actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
        { linkPageIds, status: item.parsedStatusByAi, text: item.summaryAI },
      ));
      yield put(actionAIToggleVectorizeLinkPageRS(linkPages, true));
    } else {
      yield aiSend({ payload: { linkPages: linkPageIds } });
    }
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* aiToggleVectorizeLinkPageRS(action) {
  try {
    const {
      payload: { linkPageIds, newState },
    } = action;
    if (newState) {
      yield put(actionAIProcessingToggleLoading(true, 'ai_send'));
    } else {
      yield call(requestAIUnvectorizeLinkPage(linkPageIds, newState));
      yield put(
        actionCreator(CurrentPage.aiChangeLinkPageProcessingStatus,
          { linkPageIds, status: null }));
    }
    yield put(actionAIProcessingToggleLoading(false, null));
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}
function* aiSearch(action) {
  try {
    const {
      payload: { userTask, playlistManagerId, selectedIds, setSearchData, isStop },
    } = action;
    if (isStop) {
      yield put(actionAIProcessingToggleLoading(false, null));
      return;
    }
    yield put(actionAIProcessingToggleLoading(true, 'ai_search'));
    const body = { query: userTask };
    if (selectedIds?.length) body.selectedIds = selectedIds;
    const response = yield call(requestAISearch(playlistManagerId), body);
    if (response.data?.findLinkPage?.length > 0) {
      setSearchData({ ids: response.data?.findLinkPage, matchesObj: response.data?.matches });
    }
    yield put(actionAIProcessingToggleLoading(false, null));
  } catch (e) {
    yield put(actionAIProcessingToggleLoading(false, null));
    yield showErrorMessage(e, action);
  }
}

function* updatePreviewSettings(action) {
  try {
    const { playlistManagerId, settingType, isHidden } = action.payload;
    const user = yield select(getUser);
    if (!user.id) return;
    if (playlistManagerId) yield call(requestUpdateUserSettingsPreview, { playlistManagerId, settingType, isHidden });
  } catch (err) {
    yield showErrorMessage(err, action);
  }
}

function* currentPageSagas() {
  yield takeLatest(
    CurrentPage.UpdateLinkPageDuration,
    updateDurationLinkPageState,
  );
  // yield takeEvery(CREATE_LINK_PAGE, createLinkPage);
  yield takeLatest(CurrentPage.RenameLinkedPage, editTitleLinkPage);
  yield takeLatest(CurrentPage.StopAIProcessingInSmartfile, stopAIProcessing);
  yield takeLatest(CurrentPage.Summarize, summarize);
  yield takeLatest(CurrentPage.PrepareToFind, prepareToFind);
  yield takeLatest(CurrentPage.executeAITask, aiTask);
  yield takeLatest(CurrentPage.sendToAI, aiSend);
  yield takeLatest(CurrentPage.checkAndSendToAI, checkAndSendToAI);
  yield takeLatest(CurrentPage.requestAISearch, aiSearch);
  yield takeLatest(CurrentPage.aiToggleVectorizeLinkPageRS, aiToggleVectorizeLinkPageRS);
  yield takeLatest(
    CurrentPage.ChangeTextElementBlockRedux,
    ChangeTextElementBlockRedux,
  );
  yield takeLatest(
    CurrentPage.UpdateIsShowCaptionState,
    updateLinkPageCaptionShowState,
  );
  yield takeLatest(
    CurrentPage.UpdateRemixState,
    updateIsRemixLockedLinkPageState,
  );
  yield takeLatest(
    CurrentPage.UpdateRemixStateUPV,
    updateIsRemixLockedLinkPageState,
  );
  yield takeLatest(CurrentPage.UpdateCaption, updateLinkPageCaption);
  // yield takeEvery(DELETE_LINK_PAGE, deleteLinkPage);
  // // yield takeEvery(MOVE_LINK_PAGE, moveLinkPage);
  yield takeEvery(
    CurrentPage.AddEmptyLinkPageToPlaylist,
    addEmptyLinkPageToPlaylist,
  );
  yield takeEvery(
    CurrentPage.RemoveLinkPageFromPlaylist,
    removeLinkPageFromPlaylist,
  );
  yield takeEvery(CurrentPage.UpdateLinkPage, updateLinkPage);
  yield takeEvery(CurrentPage.ChangeLinkPageTypeS, changeLinkPageType);
  yield takeEvery(CurrentPage.ClearLinkPageType, ClearLinkPageType);
  yield takeEvery(
    CurrentPage.ReplaceLinkPagePlaylistPage,
    replaceLinkPagePlaylistPage,
  );
  yield takeEvery(CurrentPage.MovePageWithinPlaylist, movePageWithinPlaylist);
  yield takeEvery(
    CurrentPage.MovePageWithinPlaylistMaker,
    movePageWithinPlaylistMaker,
  );
  yield takeEvery(
    CurrentPage.CreateLibLinkPagePlaylist,
    createLibLinkPagePlaylist,
  );
  yield takeEvery(
    CurrentPage.MultipleCreateLibLinkPagePlaylist,
    createMultipleLibLinkPagePlaylist,
  );
  yield takeEvery(CurrentPage.CreateAndUploadLinkPage, createAndUploadLinkPage);
  yield takeEvery(CurrentPage.CreateLinkPageWithURL, createLinkPageWithURL);
  yield takeEvery(CurrentPage.DuplicateLinkPageS, duplicateLinkPage);
  yield takeEvery(CurrentPage.UpdateCollapseLinkPageRS, UpdateCollapseLinkPageRS);
  // yield takeEvery(LinkPage.TurnIntoBlockS, TurnIntoBlock);
  yield takeEvery(
    CurrentPage.CancelUploadAndChangeLinkPageTypeS,
    cancelUploadAndChangeLinkPageTypeS,
  );
  //
  yield takeEvery(CurrentPage.CreateApproveButtonS, CreateApproveButton);
  yield takeEvery(CurrentPage.DeleteApproveButtonS, DeleteApproveButton);
  yield takeEvery(CurrentPage.UpdateApproveButtonS, UpdateApproveButton);
  yield takeEvery(CurrentPage.ClickToApproveButtonS, clickToApproveButton);
  //
  yield takeEvery(
    CurrentPage.InsertGoogleUploadBlockSS,
    insertGoogleUploadBlock,
  );
  yield takeEvery(
    CurrentPage.UpdateGoogleUploadBlockSS,
    UpdateGoogleUploadBlock,
  );
  yield takeEvery(CurrentPage.DeleteLinkPageSS, DeleteLinkPage);
  //
  yield takeEvery(CurrentPage.AddManyLinkPagesSS, AddManyLinkPages);
  yield takeEvery(CurrentPage.RemoveManyLinkPagesSS, RemoveManyLinkPages);
  yield takeEvery(CurrentPage.TurnIntoBlockSMaker, TurnIntoBlockMaker);
  yield takeEvery(
    EditPlaylist.ChangeTextElementBlockReduxMaker,
    ChangeTextElementBlockReduxMakerSocket,
  );
  yield takeEvery(CurrentPage.addMultiplePagesToPlaylistFromSideBar, AddMultipleFromSideBar);
  yield takeLatest(CurrentPage.markAllLinkPagesAsIncomplete, MarkAllLinkPagesAsIncomplete);
  yield takeEvery(CurrentPage.addPageToPlaylistFromSideBarDnD, AddSoloFromSideBarDnD);
  yield takeEvery(CurrentPage.addMultiplePagesToPlaylistFromSideBarDnD, AddMultipleFromSideBarDnD);
  yield takeEvery(CurrentPage.ToggleSettingPreviewRS, updatePreviewSettings);
}

export default currentPageSagas;
